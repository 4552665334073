import css from './css.svg';
import defaultIcon from './default.svg';
import ico from './favicon.svg';
import gitignore from './git_ignore.svg';
import html from './html.svg';
import md from './info.svg';
import js from './javascript.svg';
import rb from './ruby.svg';

export const fileIcons = {
  css,
  defaultIcon,
  ico,
  gitignore,
  html,
  js,
  md,
  rb,
}
